import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import logos from "../images/logos.png";

import React, { Fragment, useState, useEffect } from 'react'
import UserRow from '../components/UserRow';
import Waitlist from '../components/Waitlist';
import { GlobalContext } from '../util/GlobalContext'
import { BASE_URL, PAYMENT_LINK } from '../util/util';
import { Paper } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const { state, setState } = React.useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [userResults, setUserResults] = useState([]);
  const [search, setSearch] = useState("NYC");
  const navigate = useNavigate();
  useEffect(() => {
    searchUsers()
    if (state.jwt) refreshUser()
  }, [state.jwt])


  // useEffect(() => {
  // }, [])


  const searchUsers = () => {
    setLoading(true)
    const headers = { Authorization: "bearer " + state.jwt };
    axios.get(BASE_URL + `/users?locationSearch=${search}`, { headers: headers }).then((res) => {
      setUserResults(res.data.users);
      setSearch(res.data.location.formatted_address);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      setUserResults([])
      if (err.response.status !== 403) toast.error("None found!")
    })
  }

  const refreshUser = () => {
    setLoading(true);
    axios.get(BASE_URL + `/users/${state.user.slug}`).then((res) => {
      setLoading(false);
      setState({ ...state, user: res.data })
    }).catch(err => {
      setLoading(false);
    })
  }

  const enterListener = (event) => {
    if (event.key === 'Enter') {
      searchUsers()
    }
  }

  const goToStripe = () => {
    // window.location.href = 'https://stripe.com';
  }

  if (!state.jwt || !state.user) {
    // window.location.href = 'https://lunarpad.co';
    navigate("/login")
  }

  if (!state.user.off_waitlist) {
    return (
      <Waitlist />
    )
  }


  return (
    <div container style={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', backgroundColor: '#f7fafc' }}>
      <Paper elevation={2} xs={10} style={{ marginTop: 10, paddingLeft: 15, paddingRight: 15, paddingBottom: 20, maxWidth: '500px' }}>
        <Grid container>
          <div style={{ marginLeft: 10, textAlign: "left" }}>
            <h3 style={{ marginBottom: -6, fontSize: 30, fontWeight: 700 }}>Find awesome people 🏝</h3>
            <p style={{ maxWidth: '380px', fontSize: 18 }}>Hang with tech nomads, founders, remote workers, and peeps around the world.</p>
          </div>
          <Grid item xs={9}>
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              id="outlined-search"
              inputProps={{ style: { fontWeight: "bold" } }}
              fullWidth
              label="Where to?"
              onKeyDown={enterListener}
              type="search" />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => searchUsers()} variant="contained" fullWidth style={{ height: 55, backgroundColor: "#5664DE", color: "white", marginLeft: 5 }}>Search</Button>
          </Grid>
          <Grid item xs={12}>
            {loading && <p>Loading...</p>}
            {userResults.length !== 0 ?
              <div>
                {userResults.map(u => <UserRow user={u} />)}
              </div>
              : <div><h3>None found :( </h3><h4>Maybe try another search?</h4></div>
            }
          </Grid>
          {!state.user.is_paid ?
            <Grid item xs={12}>
              <div style={{ backgroundColor: "#DEF1FF", borderRadius: 10, padding: 10, marginTop: 20 }}>
                <h3>See more than 10 people per location</h3>
                <img src={logos} style={{ height: 100 }} />
                <br></br>
                <a href={PAYMENT_LINK} target="_blank" style={{ textDecoration: "none" }}>
                  <Button variant='contained' style={{ marginTop: 10, color: 'white', fontWeight: 'bold' }}>Upgrade</Button>
                </a>
              </div>
            </Grid> :
            <Grid item xs={12}>
              <div style={{ backgroundColor: "#DEF1FF", borderRadius: 10, padding: 10, marginTop: 20 }}>
                <h3>You're a premium member 👑</h3>
              </div>
            </Grid>
          }
        </Grid>
      </Paper>
    </div>
  )
}
