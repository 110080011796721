
import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Signup from "./pages/Signup";
import NavBar from "./components/NavBar";
import { GlobalContext } from "./util/GlobalContext";
import theme from "./util/theme";
import EditProfile from "./pages/EditProfile";
import Admin from "./pages/Admin";

function App() {
  const { state, setState } = React.useContext(GlobalContext);
  const navigate = useNavigate()

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      <div className="App">
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route exact path="/edit_profile" element={<EditProfile />} />
          <Route exact path="/signup" element={<Signup />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
