import { Grid, Button, Chip } from "@mui/material";
import React from "react";
import user_placeholder from "../images/user_placeholder.png";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  img: {
    // marginTop: "20px",
    // marginBlock: "20px",
    // display: 'block',
    width: "121px",
    height: "121px",
    objectFit: "cover",
  },
  container: {
    // border: '1px solid black',
    // borderRadius: 10,
    padding: 20,
    marginBottom: 10,
    marginTop: 10,
    // minWidth: '100%',
    // backgroundColor: "red",
  },
}));

export default function UserRow(props) {
  const classes = useStyles();

  const getColor = (pref) => {
    if (pref === "juice") {
      return "#9ac6ff";
    }
    if (pref === "drink") {
      return "#73d0f5";
    }
    if (pref === "host") {
      return "#88FFEA";
    }
    if (pref === "etc") {
      return "#6cddc3";
    }
    if (pref === "couch") {
      return "#91e184";
    }
    if (pref === "guest_room") {
      return "#ffd764";
    }
    if (pref === "spare_mattress") {
      return "#ffa97d";
    }
    if (pref === "air_mattress") {
      return "#ff9db7";
    }
    return "#ffdae4";
  };

  const CustomChip = (label) => {
    let textInset = label;
    if (label === "spare_mattress") {
      textInset = "spare mattress";
    }
    if (label === "guest_room") {
      textInset = "guest room";
    }
    if (label === "air_mattress") {
      textInset = "air mattress";
    }

    return (
      <div
        style={{
          marginLeft: 5,
          backgroundColor: getColor(label),
          width: "fit-content",
          borderRadius: 5,
          alignItems: "center",
          marginTop: 2,
          marginBottom: 2,
          padding: 4,
          paddingLeft: 6,
          paddingRight: 6,
        }}
      >
        <text style={{ fontSize: 14, fontWeight: "bolder" }}>{textInset}</text>
      </div>
    );
  };

  const withHttp = (url) =>
    !/^https?:\/\//i.test(url) ? `https://${url}` : url;

  const navigate = useNavigate();
  return (
    <Paper>
      <Grid container className={classes.container}>
        <Grid
          container
          sm={3}
          xs={12}
          sx={{
            display: { xs: "flex", sm: "block" },
            paddingLeft: { xs: 2.5, sm: 0 },
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <img
            className={classes.img}
            alt=""
            src={props.user.profile_image || user_placeholder}
            style={{ marginBottom: 10 }}
          />
          <Button
            sx={{
              fontSize: 14,
              fontWeight: "bolder",
              whiteSpace: "noWrap",
              display: { sm: "none", xs: "block" },
              float: "left",
              backgroundColor: "black",
              color: "white",
              marginBottom: 1.5,
            }}
            onClick={() => navigate(`/profile/${props.user.slug}`)}
          >
            View profile
          </Button>
        </Grid>
        <Grid
          item
          sm={9}
          xs={12}
          sx={{ display: { xs: "flex" }, flexDirection: { xs: "column" } }}
        >
          <Grid
            item
            sm={10}
            xs={12}
            style={{ paddingLeft: 20, marginTop: -10 }}
          >
            <Grid item xs={12} style={{ display: "flex", marginBottom: 5 }}>
              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "flex-start",
                  paddingTop: 5,
                }}
              >
                <text
                  style={{
                    textAlign: "left",
                    fontWeight: "bolder",
                    marginBottom: 3,
                    textOverflow: "ellipsis",
                    fontSize: 18,
                  }}
                >
                  {props.user.name}
                </text>
                <text
                  style={{
                    textAlign: "left",
                    textOverflow: "ellipsis",
                    whiteSpace: "noWrap",
                    marginBottom: 5,
                    fontWeight: "bolder",
                    color: "#333",
                  }}
                >
                  {props.user.formatted_address ||
                    props.user.location.formatted_address}
                </text>
                {!!props.user.linkedin && (
                  <a
                    href={withHttp(props.user.linkedin)}
                    target="_blank"
                    style={{ textAlign: "left", color: "black" }}
                  >
                    Linkedin
                  </a>
                )}
                <div style={{ marginTop: 3 }}></div>
                {!!props.user.website && (
                  <a
                    href={withHttp(props.user.website)}
                    target="_blank"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {props.user.website}
                  </a>
                )}
              </Grid>
              <Grid item sm={3} xs={12}>
                <Button
                  sx={{
                    fontSize: 14,
                    fontWeight: "bolder",
                    whiteSpace: "noWrap",
                    display: { xs: "none", sm: "block" },
                    backgroundColor: "black",
                    color: "white",
                  }}
                  onClick={() => {
                    props.isAdmin
                      ? props.takeOffWaitlist(props.user.slug)
                      : navigate(`/profile/${props.user.slug}`);
                  }}
                >
                  {props.isAdmin ? "Activate" : "View profile"}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <text style={{ textAlign: "left", marginBottom: 0 }}>
                  {props.user.bio}
                </text>
              </div>
              <div style={{ float: "left" }}>
                {props.user.hosting_prefs.length !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: 0,
                      alignItems: "center",
                    }}
                  >
                    Hosting offer:
                    {props.user.hosting_prefs.map((h) => CustomChip(h))}
                  </div>
                )}
                {props.user.hang_prefs.length !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: 10,
                      alignItems: "center",
                    }}
                  >
                    Hang prefs:{props.user.hang_prefs.map((h) => CustomChip(h))}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const styles = {
  viewProfileButton: {
    fontSize: 14,
    fontWeight: "bolder",
    whiteSpace: "noWrap",
    // display: {xs: 'none', sm: 'block'},
    backgroundColor: "black",
    color: "white",
  },
};
