import { Button, Grid, Paper, TextField } from '@mui/material'
import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { GlobalContext } from '../util/GlobalContext'
import { BASE_URL } from '../util/util'

export default function Waitlist() {
    const [refEmail, setRefEmail] = React.useState("")

    const [loading, setLoading] = React.useState(false);
    const { state, setState } = React.useContext(GlobalContext);

    const paperStyle = { padding: '30px 20px', maxWidth: 280, margin: "20px auto", marginTop: '10%' }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setRef()
        }
    }

    const setRef = () => {
        setLoading(true)
        const headers = { Authorization: "bearer " + state.jwt };
        axios.post(BASE_URL + "/users/referral", { email: refEmail }, { headers }).then((res) => {
            setRefEmail("")
            toast.success("Added referral");
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast.error("Failed. Maybe you already entered this email before...");
        })
    }


    return (
        <Grid container style={{backgroundColor: '#f7fafc', height: '100vh'}}>
            <Grid item xs={1} md={3}></Grid>
            <Grid item md={6} xs={10}>
                <Paper style={paperStyle}>
                    <h2>So close! Your signup is in reviewal</h2>
                    <p>We're building one of the best vibes communities on the internet ✨ We'll let you know when your application status changes.</p>
                    <p style={{color: '#6d6d6d' }}>List members below to support your application. We'll send them a unique referral code. This helps you and them get in faster.</p>
                    <p style={{color: '#6d6d6d' }}>You can list up to five referrals.</p>
                    <TextField
                        type="text"
                        onKeyDown={handleKeyDown}
                        value={refEmail}
                        style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                        placeholder="joe@smith.com"
                        fullWidth
                        id="email"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setRefEmail(e.target.value)}
                        label="Email of referral"
                        variant="outlined"
                    />
                    {loading && <p>Loading...</p>}
                    <Button style={{backgroundColor: '#000', marginTop: 10, color: '#fff', fontWeight: 'bolder', color: 'white'}}disabled={loading | !refEmail} variant='contained' onClick={setRef} fullWidth>Enter</Button>
                </Paper>
            </Grid>
        </Grid>
    )
}
