import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Grid,
  Button,
  Paper,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import axios from "axios";
import user_placeholder from "../images/user_placeholder.png";
import { useNavigate, Link } from "react-router-dom";
import { BASE_URL } from "../util/util";
import { toast } from "react-toastify";

export default function Signup(props) {
  const [email, setEmail] = useState("");
  const [vouchEmail, setVouchEmail] = useState("");
  const [bio, setBio] = useState("");
  const [name, setName] = useState("");
  const [linkedIn, setlinkedIn] = useState("");
  const [website, setWebsite] = useState("");
  const [city, setCity] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [hangPrefs, setHangPrefs] = useState([]);
  const [hostPrefs, setHostPrefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNomad, setIsNomad] = useState(false);
  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState("");
  const [vouch, setVouch] = useState("");

  let navigate = useNavigate();

  const paperStyle = {
    padding: "30px 20px",
    maxWidth: 500,
    margin: "20px auto",
    marginTop: "10%",
  };

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      // const baseFile = await getBlob(event.target.files[0])
      // console.log(baseFile);
      let file = event.target.files[0];
      setImage(file);
      // console.log(event.target.files[0])
      setImageType(event.target.files[0].type);
    }
  };

  const getBlob = async (fileUri) => {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();

    return imageBody;
  };

  const getPresignedPostUrl = async () => {
    const res = await axios.post(BASE_URL + "/users/image", {
      fileType: imageType,
    });
    console.log(res.data);
    return res.data;
  };

  async function uploadToS3({ fileType, fileContents }) {
    const presignedPostUrl = await getPresignedPostUrl(fileType);
    const formData = new FormData();
    formData.append("file", image);
    const response = await axios.put(presignedPostUrl, image, {
      headers: { "Content-Type": imageType },
    });

    return presignedPostUrl.split("?")[0];
  }

  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validateLinkedin = (link) => {
    return String(link)
      .toLowerCase()
      .match(/([w]{3}\.)?linkedin\.com\/in\/([a-zA-Z0-9-]{5,30})\/?/);
  };

  const validateInputs = () => {
    const errors = [];
    if (!name || !email || !bio || (!city && !isNomad)) {
      errors.push("Name, email, bio, city required");
    }
    if (!validateEmail(email)) {
      errors.push("Must be a valid email");
    }

    if (!validateLinkedin(linkedIn)) {
      errors.push("A valid linkedin is required");
    }
    return errors;
  };
  const trySignUp = async () => {
    setLoading(true);

    const url = "/users";
    let profile_image = "";
    const errors = validateInputs();
    if (errors.length !== 0) {
      toast.error(errors.join(". "));
      setLoading(false);
      return;
    }
    if (image) {
      profile_image = await uploadToS3({
        fileType: imageType,
        fileContents: image,
      });
    } else {
      toast.error("Image required");
      setLoading(false);
      return;
    }
    axios
      .post(BASE_URL + url, {
        name,
        email,
        bio,
        linkedIn,
        website,
        referral_code: referralCode,
        given_city: city,
        host_prefs: hostPrefs,
        hang_prefs: hangPrefs,
        vouch_email: vouchEmail,
        profile_image,
      })
      .then((res) => {
        toast.success("Signed up!");
        setLoading(false);
        navigate("/login", { state: { codeSent: true, email } });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data) {
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong!");
        }
        setLoading(false);
      });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onHangChange = (e) => {
    const value = e.target.value;
    if (hangPrefs.includes(value)) {
      if (value === "host") {
        setHostPrefs([]);
      }
      setHangPrefs(hangPrefs.filter((x) => x != value));
    } else {
      setHangPrefs([...hangPrefs, value]);
    }
  };

  const onHostChange = (e) => {
    const value = e.target.value;
    if (hostPrefs.includes(value)) {
      setHostPrefs(hostPrefs.filter((x) => x != value));
    } else {
      setHostPrefs([...hostPrefs, value]);
    }
  };
  const hangPrefValues = {
    drink: "Get a coffee/drink/juice",
    host: "Host or Sublet",
    adventure: "Go for a jog/climb/venture",
    etc: "Etc.",
  };
  const hostPrefvalues = {
    sublet: "Sublet",
    couch: "Couch",
    guest_room: "Guest Room",
    air_mattress: "Air mattress",
    spare_mattress: "Spare Mattress",
  };

  const hangBoxes = Object.entries(hangPrefValues).map(([key, value]) => {
    return (
      <FormControlLabel
        control={<Checkbox value={key} onChange={onHangChange} />}
        label={value}
      />
    );
  });
  const hostBoxes = Object.entries(hostPrefvalues).map(([key, value]) => {
    return (
      <FormControlLabel
        control={<Checkbox value={key} onChange={onHostChange} />}
        label={value}
      />
    );
  });
  return (
    <Grid container style={{ backgroundColor: "#f7fafc" }}>
      <Grid item md={3} xs={1} />
      <Grid
        item
        md={6}
        xs={10}
        style={{ justifyContent: "center", alignContent: "center" }}
      >
        <Paper elevation={20} style={paperStyle}>
          <h5
            style={{
              fontSize: 45,
              marginTop: 30,
              marginBottom: 10,
              color: "#333",
              fontWeight: 900,
            }}
          >
            Apply to LunarPad
          </h5>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="left"
            justifyContent="center"
            paddingLeft={5}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: 5,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              Cowork, hang out, and meet awesome peeps around the world ✌️
              Application based. Find fun nights, inspiration, or sublets ⚡
            </p>
            <p
              style={{
                fontSize: 18,
                marginTop: 0,
                marginBottom: 0,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              Requirements:
            </p>
            <p
              style={{
                fontSize: 18,
                marginTop: 8,
                marginBottom: 0,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              - Bring good vibes
            </p>
            <p
              style={{
                fontSize: 18,
                marginTop: 8,
                marginBottom: 0,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              - Be willing to engage
            </p>
            <p
              style={{
                fontSize: 18,
                marginTop: 8,
                marginBottom: 0,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              - Be kind
            </p>
            <p
              style={{
                fontSize: 18,
                marginTop: 20,
                marginBottom: 30,
                textAlign: "left",
                maxWidth: "400px",
              }}
            >
              We built lunarpad as a way to meet other people who are
              interesting and excited about life. We're excited to have you
              checking it out
            </p>
          </Grid>
          <Grid container>
            <Grid item md={1} xs={1}></Grid>
            <Grid item xs={10} md={10}>
              <Fragment>
                <div className="formBlock">
                  <TextField
                    type="text"
                    value={name}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    placeholder="Satoshi Hopper"
                    fullWidth
                    id="name"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setName(e.target.value)}
                    label="Enter your name"
                    variant="outlined"
                  />
                </div>

                <div className="formBlock">
                  <TextField
                    type="email"
                    value={email}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    // style={{ paddingLeft: 80, paddingRight: 80 }}
                    placeholder="satoshi_hopper@gmail.com"
                    fullWidth
                    id="email"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Enter your email"
                    // variant="outlined"
                  />
                </div>
                <div className="formBlock">
                  <TextField
                    type="text"
                    value={website}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    placeholder="me.com"
                    fullWidth
                    id="website"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setWebsite(e.target.value)}
                    label="Enter your website (optional)"
                    variant="outlined"
                  />
                </div>
                <div className="formBlock">
                  {!isNomad && (
                    <TextField
                      type="text"
                      value={city}
                      style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                      placeholder="Mexico City, MX"
                      fullWidth
                      id="city"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setCity(e.target.value)}
                      label="What city are you based in?"
                      variant="outlined"
                    />
                  )}
                  {!city && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        label="I'm a nomad"
                        checked={isNomad}
                        onChange={() => setIsNomad(!isNomad)}
                        labelStyle={{ color: "black" }}
                        iconStyle={{ fill: "black" }}
                      />
                      <FormLabel style={{ fontSize: 12 }}>
                        Or, 'I'm completely nomadic'
                      </FormLabel>
                    </div>
                  )}
                </div>
                <div className="formBlock">
                  <TextField
                    type="text"
                    value={referralCode}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    placeholder="Code"
                    fullWidth
                    id="code"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setReferralCode(e.target.value)}
                    label="Referral Code (optional)"
                    variant="outlined"
                  />
                </div>
                <div className="formBlock">
                  <TextField
                    type="text"
                    value={linkedIn}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    placeholder="http://linkedin.com/username"
                    fullWidth
                    id="linkedin"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setlinkedIn(e.target.value)}
                    label="LinkedIn"
                    variant="outlined"
                  />
                </div>
                <div
                  className="formBlock"
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={image ? URL.createObjectURL(image) : user_placeholder}
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: "5%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                  <div style={{ height: 20 }} />
                  <Button
                    variant="contained"
                    component="label"
                    style={{ backgroundColor: "black" }}
                  >
                    Upload Profile Photo
                    <input onChange={onImageChange} type="file" hidden />
                  </Button>
                </div>
                <div className="formBlock">
                  <TextField
                    type="text"
                    value={bio}
                    style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                    placeholder="Tell us about yourself"
                    fullWidth
                    id="bio"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setBio(e.target.value)}
                    label="Bio"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </div>
                <div className="formBlock">
                  <FormGroup>
                    <p
                      style={{
                        fontSize: 18,
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      Choose what you'd want to do with others in your locale
                    </p>
                    {hangBoxes}
                  </FormGroup>
                </div>
                {hangPrefs.includes("host") && (
                  <div className="formBlock">
                    <FormGroup>
                      <p
                        style={{
                          fontSize: 18,
                          textAlign: "left",
                          fontWeight: 600,
                        }}
                      >
                        What type of accommodation can you provide
                      </p>
                      {hostBoxes}
                    </FormGroup>
                  </div>
                )}
                <div className="formBlock">
                  <FormGroup>
                    <p
                      style={{
                        fontSize: 18,
                        textAlign: "left",
                        fontWeight: 600,
                      }}
                    >
                      *Optional - Boost your chances of getting in by adding the
                      email of someone else who might like this
                    </p>
                    <div className="formBlock">
                      <TextField
                        type="email"
                        value={vouchEmail}
                        style={{ backgroundColor: "#fff", fontWeight: "bold" }}
                        placeholder="Vin.Diesel@google.com"
                        fullWidth
                        id="vouchemail"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setVouchEmail(e.target.value)}
                        label="Vouch Email"
                        rows={4}
                        variant="outlined"
                      />
                    </div>
                  </FormGroup>
                </div>
                {loading && <h3>Loading...</h3>}
                <div className="formBlock">
                  <Button
                    variant="contained"
                    disabled={!email || loading}
                    size="large"
                    style={{
                      background: "#000",
                      paddingHorizontal: 10,
                      paddingVertical: 7,
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "13",
                    }}
                    onClick={trySignUp}
                  >
                    Sign Up
                  </Button>
                </div>
              </Fragment>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
