import React, { Fragment, useState, useEffect } from 'react'
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";

import { GlobalContext } from "../util/GlobalContext";
import { useNavigate } from "react-router-dom";

export default function NavBar({ logggedIn }) {

  const { state, setState } = React.useContext(GlobalContext);
  const navigate = useNavigate()

  const logout = () => {
    setState({ jwt: "", user: {}, account: {}, currentUserId: null });
    localStorage.removeItem("id_token");
    window.location.href = 'https://lunarpad.co';
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ display: "flex", justifyContent: "space-between", backgroundColor: '#fff', paddingTop: '6px', paddingBottom: '6px', paddingLeft: { sm: '10%', xs: '2%' }, paddingRight: { sm: '10%', xs: '2%' }}}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://lunarpad.co';
            }}>
            <Typography variant="h5" component="div" style={{
              fontWeight: 900, color: '#333', size: 60, fontFamily: `'-apple-system',
              'BlinkMacSystemFont',
              "Segoe UI",
              'Roboto',
              "Helvetica Neue",
              'Arial',
              'sans-serif',
              "Apple Color Emoji",
              "Segoe UI Emoji",
              "Segoe UI Symbol"`}}>
              LunarPad 🪐
            </Typography>
          </IconButton>
          {!!state.jwt ?
            <div>
              <Button color="inherit" sx={styles.navbarTitle} onClick={() => navigate("/")}>Home</Button>
              <Button color="inherit" sx={styles.navbarTitle} onClick={() => navigate(`/profile/${state.user.slug}`)}>Profile</Button>
              <Button color="inherit" sx={styles.navbarTitle} onClick={logout}>Logout</Button>
            </div>
            :
            <div>
              {/*<Button color="inherit" style={{ color: 'black' }} onClick={() => navigate("/login")}>Login</Button>*/}
              {/*<Button color="inherit" style={{ color: 'black' }} onClick={() => navigate("/signup")}>Signup</Button>*/}
            </div>}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const styles = {
  navbarTitle: {
    fontWeight: 900,
    color: '#333',
    paddingLeft: 0,
    paddingRight: 1,
  }
}
