import React, { Fragment, useState, useEffect } from "react";
import { Card, Paper, Grid, Button, TextField } from "@mui/material";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { BASE_URL } from "../util/util";
import { GlobalContext } from "../util/GlobalContext";
export default function Login(props) {
    const { state } = useLocation();
    const { setState } = React.useContext(GlobalContext);
    const [email, setEmail] = useState(state && state.email || "");
    const [verifyCode, setVerifyCode] = useState("");
    const [codeSent, setCodeSent] = useState(state && state.email ? true : false);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const paperStyle = { padding: '30px 20px', maxWidth: 500, margin: "20px auto", marginTop: '10%' }


    const sendCode = () => {
        setLoading(true);
        const url = "/auth/code";
        axios
            .post(BASE_URL + url, { email })
            .then((res) => {
                setCodeSent(true);
                setUserId(res.data.userId);
                setLoading(false);
            })
            .catch((err) => {
                toast.error("Hmm 🤔 We don't seem to have that email. Make sure you sign up, first.")
                setLoading(false);
                setCodeSent(false);
                setEmail("");
            });
    };

    const verifyLogin = () => {
        setLoading(true);
        const url = "/auth/login";
        const data = { email, verifyCode };
        axios
            .post(BASE_URL + url, data)
            .then((res) => {
                setLoading(false)
                toast.success("Logged in!")
                setState({ jwt: res.data.token, user: res.data.user, currentUserID: res.data.user.id });
                localStorage.setItem("id_token", res.data.token);
                navigate("/");
            })
            .catch((err) => {
                setLoading(false);
                toast.error("Wrong code!")
            });
    };

    const resend = () => {
        setEmail("")
        setCodeSent(false);
    }
    return (
        <Grid container style={{ backgroundColor: '#f7fafc', height: '100vh' }}>
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10} style={{ justifyContent: "center", alignContent: "center" }}>
                <Paper elevation={20} style={paperStyle}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <h3 style={{ fontSize: 45, marginTop: 10, marginBottom: 2, fontWeight: 900, color: '#333' }}>Hey There ✌️</h3>
                        <p style={{ fontSize: 20, marginTop: 8, alignText: 'center', marginBottom: -6, maxWidth: '400px' }}>{codeSent ? "Check your email for a login code!" : "Welcome to LunarPad. Sign in below."}</p>
                    </Grid>

                    <Grid container>
                        <Grid item md={3} xs={1}></Grid>
                        <Grid item xs={10} md={6}>
                            {loading && <h3>Loading...</h3>}
                            {codeSent ? (
                                <Fragment>
                                    <div className="formBlock">
                                        <TextField
                                            type="text"
                                            style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                            value={verifyCode}
                                            placeholder="123456"
                                            id="verifyCode"
                                            fullWidth
                                            onChange={(e) => setVerifyCode(e.target.value)}
                                            label="Verify Code"
                                            variant="outlined"
                                        />
                                    </div>
                                    <p style={{ color: '#8492a6', fontWeight: 'bolder' }}><i>Be sure to check your spam</i></p>
                                    <div className="formBlock">
                                        <Button
                                            variant="contained"
                                            style={{ background: '#2190ec', paddingHorizontal: 10, paddingVertical: 7, color: 'white', fontWeight: 'bold', borderRadius: '13' }}
                                            size="large"
                                            disabled={!verifyCode || loading}
                                            onClick={verifyLogin}
                                        >
                                            Verify
                                        </Button>
                                    </div>
                                    <a onClick={resend} style={{ cursor: "pointer" }}>Don't see it? Resend</a>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <div className="formBlock">
                                        <TextField
                                            type="text"
                                            value={email}
                                            style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                            // style={{ paddingLeft: 80, paddingRight: 80 }}
                                            placeholder="joe@smith.com"
                                            fullWidth
                                            id="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            label="Enter your email"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="formBlock">
                                        <Button
                                            variant="contained"
                                            disabled={!email || loading}
                                            size="large"
                                            style={{ background: '#000', paddingHorizontal: 10, paddingVertical: 7, color: 'white', fontWeight: 'bold', borderRadius: '13' }}
                                            onClick={sendCode}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
