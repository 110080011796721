import React, { Fragment, useState, useEffect } from "react";
import { Card, Grid, Button, Paper, TextField, FormGroup, FormControlLabel, Checkbox, FormLabel } from "@mui/material";
import axios from "axios";
import { toast } from 'react-toastify';

import { useNavigate, Link } from "react-router-dom";
import { BASE_URL } from "../util/util";
import { GlobalContext } from "../util/GlobalContext";

export default function EditProfile(props) {
    const { state, setState } = React.useContext(GlobalContext);
    const [isNomad, setIsNomad] = useState(!state.user.location_id);
    const [bio, setBio] = useState("");
    const [linkedIn, setlinkedIn] = useState("");
    const [website, setWebsite] = useState("");
    const [city, setCity] = useState("");
    const [hangPrefs, setHangPrefs] = useState([]);
    const [hostPrefs, setHostPrefs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState("");
    const [imageType, setImageType] = useState("");
    const [vouch, setVouch] = useState("");

    const [user, setUser] = useState({});
    useEffect(() => {
        getUser()
    }, [state.jwt])

    const getUser = () => {
        axios.get(BASE_URL + `/users/${state.user.slug}`).then((res) => {
            setUser(res.data);
            console.log(res.data);
            setBio(res.data.bio)
            setlinkedIn(res.data.linkedin)
            setCity(res.data.given_city);
            setWebsite(res.data.website);
            setHangPrefs(res.data.hang_prefs)
            setHostPrefs(res.data.hosting_prefs);
        }).catch((err) => {
            console.log(err)
        })
    }
    let navigate = useNavigate();

    const paperStyle = { padding: '30px 20px', maxWidth: 500, margin: "20px auto", marginTop: '10%' }

    const onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            // const baseFile = await getBlob(event.target.files[0])
            // console.log(baseFile);
            let file = event.target.files[0];
            setImage(file);
            // console.log(event.target.files[0])
            setImageType(event.target.files[0].type);
        }
    }

    const getBlob = async (fileUri) => {
        const resp = await fetch(fileUri);
        const imageBody = await resp.blob();

        return imageBody;
    };


    const getPresignedPostUrl = async () => {
        const res = await axios.post(BASE_URL + "/users/image", { fileType: imageType })
        console.log(res.data);
        return res.data
    }

    async function uploadToS3({
        fileType,
        fileContents,
    }) {

        const presignedPostUrl = await getPresignedPostUrl(fileType);
        const formData = new FormData()
        formData.append('file', image);
        const response = await axios.put(presignedPostUrl, image, {
            headers: { 'Content-Type': imageType },
        });

        return presignedPostUrl.split("?")[0];
    }

    const trySignUp = async () => {
        setLoading(true);
        const url = `/users/${state.user.slug}`;
        let profile_image = null;
        if (image) {
            profile_image = await uploadToS3({ fileType: imageType, fileContents: image })
        }
        const headers = { Authorization: "bearer " + state.jwt };
        axios
            .patch(BASE_URL + url, { bio, linkedIn, website, given_city: city, host_prefs: hostPrefs, hang_prefs: hangPrefs, ...(profile_image && { profile_image }) }
                , { headers: headers })
            .then((res) => {
                setLoading(false);
                toast.success("Updated!")
                navigate(`/profile/${state.user.slug}`)
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong")
                // alert("Something went wrong");
                setLoading(false);
            });
    }


    const onHangChange = (e) => {
        const value = e.target.value;
        if (hangPrefs.includes(value)) {
            if (value === "host") {
                setHostPrefs([])
            }
            setHangPrefs(hangPrefs.filter(x => x != value))
        } else {
            setHangPrefs([...hangPrefs, value])
        }
    }

    const onHostChange = (e) => {
        const value = e.target.value;
        if (hostPrefs.includes(value)) {
            setHostPrefs(hostPrefs.filter(x => x != value))
        } else {
            setHostPrefs([...hostPrefs, value])
        }
    }
    const hangPrefValues = { drink: "Get a coffee/drink/juice", host: "Host (max a few nights)", adventure: "Go for a jog/climb/venture", etc: "Etc." }
    const hostPrefvalues = { sublet: "Sublet", couch: "Couch", guest_room: "Guest Room", air_mattress: "Air mattress", spare_mattress: "Spare Mattress" }

    const hangBoxes = Object.entries(hangPrefValues).map(([key, value]) => {
        return (
            <FormControlLabel control={<Checkbox checked={hangPrefs.includes(key)} value={key} onChange={onHangChange}
            />} label={value} />
        );
    })
    const hostBoxes = Object.entries(hostPrefvalues).map(([key, value]) => {
        return (
            <FormControlLabel control={<Checkbox value={key} checked={hostPrefs.includes(key)} onChange={onHostChange}
            />} label={value} />
        );
    })
    return (
        <Grid container>
            <Grid item md={3} xs={1} />
            <Grid item md={6} xs={10} style={{ justifyContent: "center", alignContent: "center" }}>
                <Paper elevation={20} style={paperStyle}>
                    <h5 style={{ fontSize: 45, marginTop: 30, marginBottom: 10, color: '#333', fontWeight: 900 }}>Apply to LunarPad</h5>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <p style={{ fontSize: 20, marginTop: 5, alignText: 'center', maxWidth: '400px' }}>Meet other founders, engineers, and designers around the world. Hang out and crash for free. Submit your application with this form.</p>
                    </Grid>
                    <Grid container>
                        <Grid item md={1} xs={1}></Grid>
                        <Grid item xs={10} md={10}>
                            {loading && <h3>Loading...</h3>}

                            <Fragment>
                                <div className="formBlock">
                                    <TextField
                                        type="text"
                                        value={website}
                                        style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                        placeholder="me.com"
                                        fullWidth
                                        id="website"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setWebsite(e.target.value)}
                                        label="Enter your website"
                                        variant="outlined"
                                    />
                                </div>
                                <div className="formBlock">
                                    {!isNomad &&
                                        < TextField
                                            type="text"
                                            value={city}
                                            style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                            placeholder="Mexico City"
                                            fullWidth
                                            id="city"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => setCity(e.target.value)}
                                            label="What city are you based in?"
                                            variant="outlined"
                                        />
                                    }
                                    {!city &&
                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Checkbox label="I'm a nomad"
                                                  checked={isNomad}
                                                  onChange={() => setIsNomad(!isNomad)}
                                                  labelStyle={{ color: 'black' }}
                                                  iconStyle={{ fill: 'black' }}
                                        />
                                        <FormLabel style={{ fontSize: 12 }}>Or, 'I'm completely nomadic'</FormLabel>
                                    </div>
                                    }
                                </div>
                                <div className="formBlock">
                                    <TextField
                                        type="text"
                                        value={linkedIn}
                                        style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                        placeholder="http://linkedin.com/username"
                                        fullWidth
                                        id="linkedin"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setlinkedIn(e.target.value)}
                                        label="LinkedIn"
                                        variant="outlined"
                                    />
                                </div>
                                <div className="formBlock" style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}>
                                    <img src={image ? URL.createObjectURL(image) : user.profile_image} style={{ height: 100, width: 100, borderRadius: "5%" }} alt="" />
                                    <div style={{ height: 20 }} />
                                    <Button
                                        variant="contained"
                                        component="label"
                                        style={{ backgroundColor: 'black' }}
                                    >
                                        Edit Profile Photo
                                        <input
                                            onChange={onImageChange}
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                                </div>
                                <div className="formBlock">
                                    <TextField
                                        type="text"
                                        value={bio}
                                        style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                                        placeholder="Tell us about yourself"
                                        fullWidth
                                        id="bio"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => setBio(e.target.value)}
                                        label="Bio"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                    />
                                </div>
                                <div className="formBlock">
                                    <FormGroup>
                                        <p style={{ fontSize: 18, textAlign: 'left', fontWeight: 600 }}>Choose what you'd want to do with others in your locale
                                        </p>
                                        {hangBoxes}
                                    </FormGroup>
                                </div>
                                {hangPrefs.includes('host') &&
                                    <div className="formBlock">
                                        <FormGroup>
                                            <p style={{ fontSize: 18, textAlign: 'left', fontWeight: 600 }}>What type of accommodation can you provide</p>
                                            {hostBoxes}
                                        </FormGroup>
                                    </div>
                                }
                                <div className="formBlock">
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        size="large"
                                        style={{ background: '#000', paddingHorizontal: 10, paddingVertical: 7, color: 'white', fontWeight: 'bold', borderRadius: '13' }}
                                        onClick={trySignUp}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </Fragment>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
