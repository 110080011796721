import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

import React, { Fragment, useState, useEffect } from 'react'
import { GlobalContext } from '../util/GlobalContext'
import { BASE_URL, PAYMENT_LINK } from '../util/util';
import { Paper } from '@material-ui/core';
import UserRow from '../components/UserRow';


export default function Admin() {
    const { state, setState } = React.useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [userResults, setUserResults] = useState([]);


    useEffect(() => {
        getUsers()
    }, [state.jwt])

    const getUsers = () => {
        setLoading(true)
        const headers = { Authorization: "bearer " + state.jwt };
        axios.get(BASE_URL + `/users/waitlist_users`, { headers: headers }).then((res) => {
            console.log(res.data)
            setUserResults(res.data);
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            setUserResults([])
        })
    }

    const takeOffWaitlist = (slug) => {
        setLoading(true)
        const headers = { Authorization: "bearer " + state.jwt };
        axios.post(BASE_URL + `/users/activate_user`, { slug }, { headers: headers }).then((res) => {
            getUsers()
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            toast.error("Error")
        })
    }
    return (
        <div>
            <Grid item xs={12}>
                {loading && <p>Loading...</p>}
                {userResults.length !== 0 ?
                    <div>
                        {userResults.map(u => <UserRow takeOffWaitlist={takeOffWaitlist} isAdmin user={u} />)}
                    </div>
                    : <div><h3>None found :( </h3><h4>Maybe try another search?</h4></div>
                }
            </Grid>
        </div>
    )
}
