import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import { Card, Grid, Button, Paper, Chip, TextField, Box, Typography, FormGroup, FormControlLabel, Checkbox, Modal } from "@mui/material";
import { GlobalContext } from '../util/GlobalContext'
import user_placeholder from '../images/user_placeholder.png';
import { BASE_URL, PAYMENT_LINK } from '../util/util'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// const paperStyle = { padding: '30px 20px', maxWidth: 500, margin: "20px auto", marginTop: '10%' }
const style = {
    width: '100%',
    bgcolor: 'background.paper',
};
export default function Profile(props) {
    const { state, setState } = React.useContext(GlobalContext);
    const params = useParams()
    const navigate = useNavigate()
    const [user, setUser] = useState({});
    const [message, setMessage] = useState("");
    useEffect(() => {
        getUser()
    }, [])

    const [openModal, setOpenModal] = useState(false)
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const getUser = () => {
        axios.get(BASE_URL + `/users/${params.id}`).then((res) => {
            setUser(res.data)
            console.log(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const getColor = (pref) => {
        if (pref === "juice") {
            return "#9ac6ff"
        }
        if (pref === "drink") {
            return "#73d0f5"
        }
        if (pref === "host") {
            return "#88FFEA"
        }
        if (pref === "etc") {
            return "#6cddc3"
        }
        if (pref === "couch") {
            return "#91e184"
        }
        if (pref === "guest_room") {
            return "#ffd764"
        }
        if (pref === "spare_mattress") {
            return "#ffa97d"
        }
        if (pref === "air_mattress") {
            return "#ff9db7"
        }
        return "#ffdae4"
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const sendMessage = () => {
        const headers = { Authorization: "bearer " + state.jwt };
        axios.post(BASE_URL + "/users/message", { message, userId: user.id }, { headers }).then((res) => {
            setOpenModal(false);
            toast.success("Message sent!")
        }).catch((err) => {
            toast.error("Error sending your message :(")
        })
    }

    const withHttp = url => !/^https?:\/\//i.test(url) ? `https://${url}` : url;


    const CustomChip = (label) => {
        let textInset = label;
        if (label === "spare_mattress") {
            textInset = 'spare mattress'
        }
        if (label === "guest_room") {
            textInset = "guest room"
        }
        if (label === "air_mattress") {
            textInset = "air mattress"
        }

        return (
            <div style={{ marginLeft: 5, backgroundColor: getColor(label), width: "fit-content", borderRadius: 8, alignItems: 'center', marginTop: 2, marginBottom: 2, padding: 4, paddingLeft: 6, paddingRight: 6 }}>
                <text style={{ fontSize: 14, fontWeight: 'bolder' }}>{textInset}</text>
            </div>

        );
    };
    return (
        <div style={{ backgroundColor: '#f7fafc', minHeight: '100vh' }}>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: 20 }}>
                        Send a message to {user.name}
                    </Typography>
                    <TextField
                        type="text"
                        value={message}
                        style={{ backgroundColor: '#fff', fontWeight: 'bold' }}
                        placeholder="What do you want to say?"
                        fullWidth
                        id="message"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => setMessage(e.target.value)}
                        label="Message"
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                    <Button style={{ marginTop: 20 }} variant='contained' fullWidth onClick={sendMessage}>Send</Button>
                </Box>
            </Modal>
            <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: { xs: 2 }, paddingRight: { xs: 2 } }}>
                <Paper elevation={2} sx={{ marginTop: 4, width: { sm: 550, xs: 300 }, paddingBottom: { sm: 5, xs: 1 }, paddingLeft: { xs: 5 }, paddingLeft: 2, paddingRight: 2 }}>
                    <Grid container >
                        <Grid item sm={5} xs={12} sx={{ marginTop: 2 }}>
                            <img src={user.profile_image || user_placeholder} style={{ maxHeight: 190, maxWidth: 190 }} />
                            {/*{state.user.slug === params.id &&*/}
                            {/*    <React.Fragment> {*/}
                            {/*        user.is_paid ?*/}
                            {/*            <b>Active paid user 👑</b> :*/}
                            {/*            <a href={PAYMENT_LINK} style={{ textDecoration: "none" }}><Button variant='contained'>Upgrade to paid!</Button></a>*/}
                            {/*    }*/}
                            {/*    </React.Fragment>*/}
                            {/*}*/}
                            <Grid item xs={12} sx={{ display: { sm: 'block', xs: 'none' }, paddingTop: 1 }}>
                                {state.user.slug === params.id &&
                                    <React.Fragment> {
                                        user.is_paid ?
                                            <b>Premium Member 👑</b> :
                                            <a href={PAYMENT_LINK} style={{ textDecoration: "none", flexWrap: 'none' }}>
                                                <Button style={{ color: 'white', fontWeight: 'bold' }} variant='contained'>Upgrade</Button>
                                            </a>
                                    }
                                    </React.Fragment>
                                }

                            </Grid>
                        </Grid>
                        <Grid item sm={7} xs={12} style={{ textAlign: "left", paddingBottom: 15 }}>
                            <Grid container>
                                <Grid item xs={12} sm={8} sx={{ display: 'flex', flexDirection: 'column', paddingTop: { sm: 3, xs: 2 } }}>
                                    <text style={{ fontWeight: 'bolder', fontSize: 20 }}>{user.name}</text>
                                    <text style={{ marginTop: 5 }}>{user.location ? user.location.formatted_address : "Nomad"}</text>
                                    <a href={withHttp(user.linkedin)} style={{ color: "black" }} target="_blank">Linkedin</a>
                                    <div style={{ marginTop: 5 }}></div>
                                    {user.website &&
                                        <a href={withHttp(user.website)} style={{ color: "black" }} target="_blank">{user.website}</a>
                                    }
                                    <text style={{ marginBottom: 10, marginTop: 5 }}>{user.bio}</text>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>Hosting offer:{user.hosting_prefs && user.hosting_prefs.map(h => (CustomChip(h)))}</div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>Hang prefs:{user.hang_prefs && user.hang_prefs.map(h => (CustomChip(h)))}</div>
                                    {state.user.slug !== params.id &&
                                        <Button onClick={() => setOpenModal(true)} style={{ background: '#000', float: "right", marginTop: 10, marginRight: 7, color: 'white', fontWeight: 'bold', borderRadius: '13' }} variant="contained">Message</Button>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Grid item xs={6}>
                                        {state.user.slug === params.id &&
                                            <Button
                                                variant="contained"
                                                style={{ background: '#000', float: { xs: "none", sm: 'right' }, marginTop: 10, marginRight: 7, color: 'white', fontWeight: 'bold', borderRadius: '13' }}
                                                size="large"
                                                onClick={() => navigate("/edit_profile")}
                                            >
                                                Edit
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: { sm: 'none', xs: 'block' }, marginTop: 1 }}>
                                        {state.user.slug === params.id &&
                                            <React.Fragment> {
                                                user.is_paid ?
                                                    <b>Premium Pass 👑</b> :
                                                    <a href={PAYMENT_LINK} style={{ textDecoration: "none", flexWrap: "none" }}>
                                                        <Button style={{ color: 'white', fontWeight: 'bold' }} variant='contained'>Upgrade</Button>
                                                    </a>
                                            }
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </div >
    )
}
